import { DateTime } from "luxon";
import {
  AttrType,
  CallOff,
  CallOffPosition,
  Contract,
  ContractPosition,
  ObjectClass,
  ObjectField,
  Status,
  statusDetails,
  WithTimeTracking,
} from "../types";
import { isString } from "lodash";
import { decamelize } from "humps";

const deserializeWithTimeTracking: (obj: any) => WithTimeTracking = (obj) => ({
  createdTs: obj?.createdTs && DateTime.fromISO(obj?.createdTs),
  modifiedTs: obj?.modifiedTs && DateTime.fromISO(obj?.modifiedTs),
});

const serializeWithTimeTracking: (obj: WithTimeTracking) => any = (obj) => ({
  createdTs: obj?.createdTs && obj?.createdTs.toISO(),
  modifiedTs: obj?.modifiedTs && obj?.modifiedTs.toISO(),
});

export const deserializeContract: (contract: any) => Contract = (contract) => ({
  ...contract,
  ...deserializeWithTimeTracking(contract),
  totalPurchasePrice: contract?.totalPurchasePrice && parseFloat(contract?.totalPurchasePrice),
  purchaseDate: contract?.purchaseDate && DateTime.fromISO(contract?.purchaseDate),
  sourceModifiedTs: contract?.sourceModifiedTs && DateTime.fromISO(contract?.sourceModifiedTs),
  callOffs: contract?.callOffs?.map((callOff: any) => deserializeCallOff(callOff)),
  contractPositions: contract?.contractPositions?.map((pos: any) =>
    deserializeContractPosition(pos)
  ),
});

export const serializeContract: (contract: Contract) => any = (contract) => ({
  ...contract,
  ...serializeWithTimeTracking(contract),
  purchaseDate: contract?.purchaseDate && contract?.purchaseDate.toFormat("yyyy-MM-dd"),
  sourceModifiedTs: contract?.sourceModifiedTs && contract?.sourceModifiedTs.toISO(),
});

export const deserializeContractPosition: (pos: any) => ContractPosition = (pos) => ({
  ...pos,
  ...deserializeWithTimeTracking(pos),
  imo: pos?.imo && parseInt(pos?.imo),
  amount: pos?.amount && parseFloat(pos?.amount),
  amountLeft: pos?.amountLeft && parseFloat(pos?.amountLeft),
  purchasePrice: pos?.purchasePrice && parseFloat(pos?.purchasePrice),
  totalPrice: pos?.totalPrice && parseFloat(pos?.totalPrice),
  unitPrice: pos?.unitPrice && parseFloat(pos?.unitPrice),
  bulkSpaceAvailable: pos?.bulkSpaceAvailable && parseFloat(pos?.bulkSpaceAvailable),
  bagSpaceAvailable: pos?.bagSpaceAvailable && parseFloat(pos?.bagSpaceAvailable),
  departureOrigin: pos?.departureOrigin && DateTime.fromISO(pos?.departureOrigin),
  laycans: pos?.laycans && DateTime.fromISO(pos?.laycans),
  etaNola: pos?.etaNola && DateTime.fromISO(pos?.etaNola),
  etaWarehouse: pos?.etaWarehouse && DateTime.fromISO(pos?.etaWarehouse),
  sourceModifiedTs: pos?.sourceModifiedTs && DateTime.fromISO(pos?.sourceModifiedTs),
  productPurityMax: parseFloat(pos?.productPurityMax),
  productPurityMin: parseFloat(pos?.productPurityMin),
  productSizeMax: parseFloat(pos?.productSizeMax),
  productSizeMin: parseFloat(pos?.productSizeMin),
  callOffs: pos?.callOffs?.map((callOff: any) => deserializeCallOff(callOff)),
});

export const serializeContractPosition: (pos: ContractPosition) => {
  [key: string]: any;
} = (pos) => ({
  ...pos,
  ...serializeWithTimeTracking(pos),
  imo: pos?.imo === "" ? null : pos?.imo,
  amount: pos?.amount && pos?.amount.toString(),
  amountLeft: pos?.amountLeft && pos?.amountLeft.toString(),
  purchasePrice: pos?.purchasePrice && pos?.purchasePrice.toString(),
  totalPrice: pos?.totalPrice && pos?.totalPrice.toString(),
  unitPrice: pos?.unitPrice && pos?.unitPrice.toString(),
  bulkSpaceAvailable: pos?.bulkSpaceAvailable && pos?.bulkSpaceAvailable.toString(),
  bagSpaceAvailable: pos?.bagSpaceAvailable && pos?.bagSpaceAvailable.toString(),
  departureOrigin: pos?.departureOrigin && pos?.departureOrigin.toISO(),
  laycans: pos?.laycans && pos?.laycans.toISO(),
  etaNola: pos?.etaNola && pos?.etaNola.toISO(),
  etaWarehouse: pos?.etaWarehouse && pos?.etaWarehouse.toISO(),
  sourceModifiedTs: pos?.sourceModifiedTs && pos?.sourceModifiedTs.toISO(),
});

export const deserializeCallOff: (callOff: any) => CallOff = (callOff) => ({
  ...callOff,
  ...deserializeWithTimeTracking(callOff),
  commissionDate: callOff?.commissionDate && DateTime.fromISO(callOff?.commissionDate),
  eta: callOff?.eta && DateTime.fromISO(callOff?.eta),
  etd: callOff?.etd && DateTime.fromISO(callOff?.etd),
  goodsOutputDate: callOff?.goodsOutputDate && DateTime.fromISO(callOff?.goodsOutputDate),
  loadingDate: callOff?.loadingDate && DateTime.fromISO(callOff?.loadingDate),
  sourceModifiedTs: callOff?.sourceModifiedTs && DateTime.fromISO(callOff?.sourceModifiedTs),
  callOffPositions:
    callOff?.callOffPositions &&
    callOff.callOffPositions.map((pos: any) => deserializeCallOffPosition(pos)),
});

export const serializeCallOff: (callOff: CallOff) => any = (callOff) => ({
  ...callOff,
  ...serializeWithTimeTracking(callOff),
  commissionDate: callOff?.commissionDate && callOff?.commissionDate.toISO(),
  eta: callOff?.eta && callOff?.eta.toISO(),
  etd: callOff?.etd && callOff?.etd.toISO(),
  goodsOutputDate: callOff?.goodsOutputDate && callOff?.goodsOutputDate.toISO(),
  loadingDate: callOff?.loadingDate && callOff?.loadingDate.toISO(),
  sourceModifiedTs: callOff?.sourceModifiedTs && callOff?.sourceModifiedTs.toISO(),
});

export const deserializeCallOffPosition: (pos: any) => CallOffPosition = (pos) => ({
  ...pos,
  ...deserializeWithTimeTracking(pos),
  imo: pos?.imo && parseInt(pos?.imo),
  amount: pos?.amount && parseFloat(pos?.amount),
  amountLeft: pos?.amountLeft && parseFloat(pos?.amountLeft),
  purchasePrice: pos?.purchasePrice && parseFloat(pos?.purchasePrice),
  bulkSpaceAvailable: pos?.bulkSpaceAvailable && parseFloat(pos?.bulkSpaceAvailable),
  bagSpaceAvailable: pos?.bagSpaceAvailable && parseFloat(pos?.bagSpaceAvailable),
  departureOrigin: pos?.departureOrigin && DateTime.fromISO(pos?.departureOrigin),
  laycans: pos?.laycans && DateTime.fromISO(pos?.laycans),
  etaNola: pos?.etaNola && DateTime.fromISO(pos?.etaNola),
  etaWarehouse: pos?.etaWarehouse && DateTime.fromISO(pos?.etaWarehouse),
  productPurityMax: parseFloat(pos?.productPurityMax),
  productPurityMin: parseFloat(pos?.productPurityMin),
  productSizeMax: parseFloat(pos?.productSizeMax),
  productSizeMin: parseFloat(pos?.productSizeMin),
});

export function serializeFieldName<T extends ObjectClass>(
  objectClass: T,
  fieldName: ObjectField<T>
) {
  if (objectClass === "ContractPosition" && fieldName === "productNameEn") {
    return "product__name_en";
  } else if (objectClass === "CallOffPosition" && fieldName === "productNameEn") {
    return "contract_position__product__name_en";
  } else {
    return decamelize(fieldName || "");
  }
}

export const formatData: <T extends AttrType | undefined>(
  value: any,
  type: T,
  locale: string
) => string = (value, type, locale) => {
  if (!value) {
    return value;
  }
  if (type === "text") {
    return value;
  } else if (type === "date" && value instanceof Date) {
    return value.toLocaleDateString(locale, { day: "2-digit", month: "2-digit", year: "numeric" });
  } else if (type === "date" && DateTime.isDateTime(value)) {
    return (value as DateTime).toLocaleString(
      { day: "2-digit", month: "2-digit", year: "numeric" },
      { locale }
    );
  } else if (type === "datetime" && DateTime.isDateTime(value)) {
    return value.toLocaleString(DateTime.DATETIME_SHORT, { locale });
  } else if (type === "number") {
    return parseFloat(value as any)?.toLocaleString(locale, { maximumFractionDigits: 0 });
  } else if (type === "nonnegativeNumber") {
    const clippedValue = value < 0 ? 0 : value;
    return parseFloat(clippedValue as any)?.toLocaleString(locale, {
      maximumFractionDigits: 0,
    });
  } else if (type === "decimal") {
    return parseFloat(value as any)?.toLocaleString(locale, { maximumFractionDigits: 2 });
  } else if (type === "nonnegativeDecimal") {
    const clippedValue = value < 0.0 ? 0.0 : value;
    return parseFloat(clippedValue as any)?.toLocaleString(locale, {
      maximumFractionDigits: 2,
    });
  } else if (type === "currency") {
    return parseFloat(value as any)?.toLocaleString(locale, { maximumFractionDigits: 0 }) + " USD";
  } else if (type === "list") {
    return value.join(", ");
  } else if (type === "positionStatus") {
    return statusDetails[value as Status]?.label;
  } else if (type === "callOffStatus") {
    return statusDetails[value as Status]?.label;
  } else {
    return value;
  }
};

export class NumberParser {
  private _group: RegExp;
  private _decimal: RegExp;
  private readonly _numeral: RegExp;
  private _index: (d: string) => number | undefined;

  constructor(locale: string) {
    const format = new Intl.NumberFormat(locale);
    const parts = format.formatToParts(12345.6);
    const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(`[${parts.find((d) => d.type === "group")?.value}]`, "g");
    this._decimal = new RegExp(`[${parts.find((d) => d.type === "decimal")?.value}]`);
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = (d) => index.get(d);
  }

  parse(string: string) {
    const numberString = string
      .trim()
      .replace(this._group, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, (substring) => (this._index(substring) || 0).toString());
    return numberString ? +numberString : NaN;
  }
}

const defaultComparator = (v1: any, v2: any) =>
  isString(v1) && isString(v2) ? v1.localeCompare(v2) : v2 - v1;

export const comparators: Record<AttrType, (v1: any, v2: any) => number> = {
  callOffStatus: defaultComparator,
  currency: (v1: any, v2: any) => parseFloat(v2) - parseFloat(v1),
  date: defaultComparator,
  datetime: defaultComparator,
  decimal: defaultComparator,
  list: (v1: string[], v2: string[]) => defaultComparator(v1.join(","), v2.join(",")),
  positionStatus: defaultComparator,
  nonnegativeDecimal: (v1: any, v2: any) => parseFloat(v2) - parseFloat(v1),
  nonnegativeNumber: (v1: any, v2: any) => parseFloat(v2) - parseFloat(v1),
  number: (v1: any, v2: any) => parseFloat(v2) - parseFloat(v1),
  text: defaultComparator,
};
