import React, { useEffect, useState } from "react";
import { Grid, TableContainer } from "@mui/material";

import { Helmet } from "react-helmet-async";
import { CallOff, ContractPosition } from "@src/types";
import { useDashboardState } from "@src/app.state";
import { generateApiPath, useCurrentCompanyDistributorOrPurchaser } from "@src/utils/helpers";
import SusBox from "@components/SusBox";
import ProductInfoCard from "@components/widgets/ProductInfoCard";
import DropzoneArea from "@components/widgets/DropzoneArea";
import { apiBaseUrl, apiPaths, paths } from "@src/paths";
import ModuleOverviewTable from "@components/ModuleOverviewTable/ModuleOverviewTable";
import CommentBox from "@components/CommentBox/CommentBox";
import useAxios from "axios-hooks";
import { deserializeCallOff, serializeCallOff } from "@src/utils/typing";
import { isEmpty } from "lodash";
import dashboardAxios from "@src/api";
import { useNavigate, useParams } from "react-router-dom";
import useAlerts from "@hooks/useAlerts";
import useApiData from "@hooks/useApiData";

interface IDetailViewProps {
  callOff?: CallOff;
  loading?: boolean;
  onDataChange?: (data: ContractPosition) => void;
}

const CallOffDetailView: React.FC<React.PropsWithChildren<IDetailViewProps>> = ({
  onDataChange,
  children,
}) => {
  const overviewUrl = paths.callOffOverview;
  const {
    currentCompany,
    loading,
    setBreadcrumbs,
    viewSettings: {
      apiVersion,
      callOff: callOffViewSettings,
      callOffPosition: callOffPositionViewSettings,
    },
  } = useDashboardState();

  const { id } = useParams();
  const navigate = useNavigate();
  const alerts = useAlerts();
  const {
    data: rawCallOff,
    initialLoading: callOffLoading,
    error: callOffError,
  } = useApiData(generateApiPath(apiPaths.callOffDetail, { apiVersion, id }));

  const [, patchData] = useAxios(
    {
      url: generateApiPath(apiPaths.callOffDetail, { apiVersion, id }),
      method: "patch",
      withCredentials: true,
    },
    {
      manual: true,
    }
  );
  const [errors, setErrors] = useState<{ [field: string]: string[] }>({});
  const [callOff, setCallOff] = useState<CallOff | undefined>();
  const [edit, setEdit] = useState(false);

  // Set breadcrumbs
  useEffect(
    () =>
      setBreadcrumbs([
        { label: callOffViewSettings.titlePlural, url: overviewUrl },
        {
          label: callOff ? `${callOff.contractDistributorReference}-${callOff.deliveryId}` : "...",
        },
      ]),
    [callOff]
  );

  const handleSave = async (newData: CallOff) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { purchaser, distributor, ...cleanedNewData } = newData;
    const serializedCallOff = serializeCallOff(cleanedNewData);
    return patchData({ data: serializedCallOff })
      .then((response) => {
        if (response?.data) {
          setCallOff(deserializeCallOff(response.data));
          setEdit(false);
          onDataChange?.(newData);
          return true;
        }
        return false;
      })
      .catch((error) => {
        setErrors(error?.response?.data || {});
        if (error?.response?.data?.detail || isEmpty(error?.response?.data)) {
          const errorMsg = error?.response?.data?.detail;
          alerts.notifyError({
            title: "Error",
            content: errorMsg || "Sorry, something went wrong. Please try again later.",
            autoHideDuration: 5000,
          });
        }
      });
  };

  const handleEdit = () => setEdit(true);
  const handleCancelEdit = () => setEdit(false);

  const handleDelete = async (id: number) =>
    dashboardAxios
      .delete(generateApiPath(apiPaths.callOffDetail, { apiVersion: "v1", id: id.toString() }))
      .then((response) => {
        if (response.status === 204) {
          navigate(overviewUrl);
          alerts.notifySuccess({
            title: "Success",
            content: `Successfully deleted call-off with id ${id}.`,
            autoHideDuration: 5000,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          alerts.notifyError({
            title: "Error",
            content: "You don't have permission to delete this call-off.",
            autoHideDuration: 5000,
          });
        } else {
          alerts.notifyError({
            title: "Error",
            content: "Sorry, something went wrong. Please try again later.",
            autoHideDuration: 5000,
          });
        }
      });

  const companyId = useCurrentCompanyDistributorOrPurchaser(callOff)?.id;

  useEffect(() => {
    if (rawCallOff) {
      setCallOff(deserializeCallOff(rawCallOff));
      onDataChange?.(rawCallOff);
    }
  }, [rawCallOff]);

  // Show fetching errors
  useEffect(() => {
    if (!callOffError) {
      return;
    }
    alerts.notifyError({
      title: "Not found",
      content:
        callOffError?.response?.status === 404
          ? `Could not find call-off with id ${id}.`
          : "Sorry, something went wrong. Please try again later.",
      autoHideDuration: 5000,
    });
    navigate(overviewUrl);
  }, [callOffError]);

  return (
    <>
      <Helmet>
        {callOff?.contractDistributorReference && (
          <title>{`${callOff?.contractDistributorReference} - Contracts | PEK Dashboard`}</title>
        )}
      </Helmet>
      <SusBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={7}>
            <SusBox>
              <ProductInfoCard
                title={`${callOffViewSettings.titleSingular} Details`}
                data={callOff}
                loading={callOffLoading}
                idGetter={(data) => data?.id}
                errors={errors}
                lastModifiedAt={callOff?.modifiedTs}
                attrs={callOffViewSettings.fields.default.detailView}
                editable={
                  callOffViewSettings.dataModifiable &&
                  ["admin", "data_editor"].includes(currentCompany?.role || "")
                }
                edit={edit}
                onEdit={handleEdit}
                onSave={handleSave}
                onCancelEdit={handleCancelEdit}
                onDelete={handleDelete}
              />
            </SusBox>
          </Grid>
          {!callOffViewSettings.documents?.hide && (
            <Grid item xs={12} md={6} xl={5}>
              <DropzoneArea
                fileAttachments={callOff?.files || []}
                loading={callOffLoading}
                comingSoon={callOffViewSettings.documents?.comingSoon}
                url={generateApiPath(`${apiBaseUrl}${apiPaths.callOffFileUpload}`, {
                  apiVersion: "v1",
                  id: callOff?.id?.toString() || "",
                })}
              />
            </Grid>
          )}
        </Grid>
        {callOff?.callOffPositions && callOff.callOffPositions.length > 0 && (
          <Grid container spacing={3} mt={0.5}>
            <Grid item xs={12} md={12} xl={12}>
              <TableContainer sx={{}}>
                <ModuleOverviewTable
                  rows={callOff.callOffPositions || []}
                  loading={loading}
                  columns={callOffPositionViewSettings.fields.default.childList}
                  localStoragePrefix="CallOffDetailPositionsTable"
                  title={callOffPositionViewSettings.titlePlural}
                />
              </TableContainer>
            </Grid>
          </Grid>
        )}
        {children}
        {callOff?.comments && callOff?.genericRef && (
          <Grid container spacing={3} mt={0.5}>
            <Grid item xs={12} md={12} xl={12}>
              <CommentBox
                title="Comments"
                loading={callOffLoading}
                comments={callOff?.comments || []}
                commentedObjectRef={callOff?.genericRef}
                companyId={companyId}
                distributorId={callOff?.distributor?.id}
              />
            </Grid>
          </Grid>
        )}
      </SusBox>
    </>
  );
};

export default CallOffDetailView;
