import React, { useMemo, useState } from "react";

import { Grid } from "@mui/material";
import CallOffDetailView from "@components/CallOffDetailView/CallOffDetailView";
import VesselFinder from "@components/VesselFinder/VesselFinder";

const Details: React.FC = () => {
  const [imo, setImo] = useState<number | undefined>();

  const extractImo = (data: any) => {
    if (data && "imo" in data) {
      setImo(data.imo);
    }
  };
  const vesselFinder = useMemo(
    () => <VesselFinder imo={imo} showTrack title={"VesselFinder"} loading={false} />,
    [imo]
  );

  return (
    <CallOffDetailView onDataChange={extractImo}>
      {imo && (
        <Grid container spacing={3} mt={0.5}>
          <Grid item xs={12} md={12} xl={12} sx={{ height: "400px" }}>
            {vesselFinder}
          </Grid>
        </Grid>
      )}
    </CallOffDetailView>
  );
};

export default Details;
